import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import UtilsApi from "../../Services/Utils/Utils";

const WYSIWIGEditor = (props) => {
  const utilsService = new UtilsApi();
  const editorRef = useRef(null);

  const hadleUpload = async (blobInfo, success, failure) => {
    let form = new FormData();
    form.append("image", blobInfo.blob(), blobInfo.filename());
    try {
      const response = await utilsService.uploadImage(form);
      console.log(response.data.data[0].url);
      success(response.data.data[0].url);
    } catch (e) {
      failure("HTTP error");
    }
  };

  // const [data, setData] = useState(props.data);

  // const handleChange = (content) => {
  //   return props.onChange(content);
  //   // return setData(content);
  // };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINTINYMCE_KEY}
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={props.data}
      // initialValue={data}
      // onChange={(e) => {
      //   handleChange(e.target.getContent());
      // }}
      onBlur={(e) => {
        props.onChange(e.target.getContent());
      }}
      init={{
        images_upload_handler: hadleUpload,
        height: (props.height) ? props.height : 400,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount image imagetools preview table",
        ],
        toolbar: `image | undo redo | formatselect | bold italic backcolor forecolor |
          alignleft aligncenter alignright alignjustify |
          bullist numlist outdent indent | removeformat | help | code | preview | table`,
        content_style:
          "body { font-family:Kern,sans-serif; font-size:14px; } img{display:inline-block,width: 400px, height: auto}",
        image_caption: true,
      }}
      disabled={props.isDisableFields}
    />
  );
};

export default WYSIWIGEditor;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AddIcon from "../../Assets/images/add.svg";
import CheckBox from "../FormControl/CheckBox/CheckBox";

// SCSS
import styles from "./index.module.scss";

//IMAGES
import EditIcon from "../../Assets/images/edit.svg";
import DeleteIcon from "../../Assets/images/delete.svg";

// COMPONENTS
import ManageVariationModal from "../../Components/Modal/Variation/AddVariation";
import { useProduct } from "../../Context/ProductCrud/useProduct";
import { ProductFlagInfoForm } from "../../Hooks/product/productFlagFormFromik";
import { DEFAULT_STORE_ID } from "Helpers/Constants";

function ProductVariations(props) {
  const {
    productState,
    setActiveIndexVariant,
    deleteDataVariant,
    setProductFlagData,
    setEditVariantProductIndex,
    checkProductVariantData,
  } = useProduct();

  let productFlagInfoValue = {
    is_eligible_for_commission:
      productState.setProductAllData.is_eligible_for_commission ?? false,
    is_limited_availability:
      productState.setProductAllData.is_limited_availability ?? false,
    is_sold_out: productState.setProductAllData.is_sold_out ?? false,
    is_exclude_from_search:
      productState.setProductAllData.is_exclude_from_search ?? false,
    temporary_unavailable:
      productState.setProductAllData.temporary_unavailable ?? false,
  };
  const submit = (values) => { };
  const cbSubmit = (values) => {
    setProductFlagData(values);
  };

  const { formik: ProductFlagInfoFormik } = ProductFlagInfoForm(
    submit,
    productFlagInfoValue,
    cbSubmit
  );

  const [showAddModal, setShowAddModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [manageVariationPageTitle, setManageVariationPageTitle] = useState("");
  const [variation, setVariation] = useState(null);

  const handleManageVariationClick = async (
    event,
    type = "add",
    index = ""
  ) => {

    setEditVariantProductIndex(index);
    setShowAddModal(true);
    checkProductVariantData(true);
    if (type === "edit") {
      setManageVariationPageTitle("Edit Variation");
    } else {
      if (
        productState.product_variations.length > 0 &&
        (!productState.product_variations[0]["title"] ||
          !productState.product_variations[0]["price"])
      ) {
      } else {
        setActiveIndexVariant(productState.product_variations.length);
      }

      setManageVariationPageTitle("Create Variation");
    }
  };

  const handleManageVariation = async (event) => {
    
    const productInfoForm = document.getElementById(
      "product_variant_submit_button"
    );
    if (productInfoForm) {
      productInfoForm.click();
    }
    
    if (productState.productVariationError) {
    } else {
      setShowAddModal(false);
      setActiveIndexVariant(productState.product_variations.length);
      setEditVariantProductIndex("");
      // clearProductVariationData();
    }
  };

  const deleteDataProductVariant = (index) => {
    deleteDataVariant(index);
    if (productState.activeProductVariantIndex !== 0)
      setActiveIndexVariant(productState.activeProductVariantIndex - 1);
  };

  //Get current selected store Id
  let selectedStore = localStorage.getItem("selectedStore");

  return (
    <React.Fragment>
      <div className={`${styles.productsWrapper}`} id="variations">
        <div className="">
          <div className="">
            <div className={styles.title}>Variations</div>
            {productState.setPageForView === 1 ? (
              <span></span>
            ) : (
              DEFAULT_STORE_ID == selectedStore && 
             (
                <span
                className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleManageVariationClick();
                }}
              >
                <img className={styles.searchIcon} src={AddIcon} alt="" />
              </span>
             )
            )}
            <div className="table-responsive mt-2 mb-4">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-left"></th>
                    <th className="text-left">Title</th>
                    <th className="text-left">Inventory</th>
                    <th className="text-left">Price</th>
                    <th className="text-left">Commission</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {productState.product_variations.length === 0 || (productState.product_variations[0]?.title === "" || productState.product_variations[0]?.price === "" ) ? (
                    <tr>
                      <td className={``}>
                        <div className={`${styles.emptyImage}`}>
                          <span>Image</span>
                        </div>
                      </td>
                      <td className={``}>No Variations</td>
                      <td className={``}></td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td></td>
                    </tr>
                  ) : (
                    productState.product_variations?.map((d, i) => {
                      return (
                        <tr key={i}>
                          <td className={``}>
                            <div className={`${styles.tableimg}`}>
                              <img
                                src={
                                  d.image === "" ||
                                    d.image === "null" ||
                                    d.image === null
                                    ? ""
                                    : `${d.image}`
                                }
                                alt=""
                              />
                            </div>
                          </td>
                          <td className={``}>
                            {d.title}
                            <br></br>
                            <p>SKU - {d.sku}</p>
                          </td>
                          <td className={``}>{d.inventory}</td>
                          <td>${d.price}</td>
                          <td>{d.commission}</td>
                          <td>
                            <div className={styles.status}>
                              {d.status === 1 ? (
                                <div className={styles.Active}> Active </div>
                              ) : (
                                <div className={styles.Inactive}>Inactive</div>
                              )}
                            </div>
                          </td>
                          {productState.setPageForView === 1 ? (
                            <td></td>
                          ) : (
                            <td className={`link cursor-pointer`}>
                              <img
                                onClick={(e) =>
                                  handleManageVariationClick(e, "edit", i)
                                }
                                className={`${styles.searchIcon} mr-2 pb-4`}
                                src={EditIcon}
                                alt=""
                              />
                              {
                                DEFAULT_STORE_ID == selectedStore && 
                                (
                                  <img
                                  onClick={(e) => deleteDataProductVariant(i)}
                                  className={`${styles.searchIcon} mr-1 pb-4`}
                                  src={DeleteIcon}
                                  alt=""
                                  />
                                )
                              }
                             
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-2 mb-4">
              <div className={`row`}>
                <div className={`col-md-6 text-left ${styles.checkBox}`}>
                  <CheckBox
                    label="Is Eligible For Commission?"
                    value={
                      ProductFlagInfoFormik.values.is_eligible_for_commission
                    }
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductFlagInfoFormik.setValues((v) => {
                        return { ...v, is_eligible_for_commission: data };
                      })
                    }
                    error={
                      ProductFlagInfoFormik.errors.is_eligible_for_commission
                    }
                    touched={
                      ProductFlagInfoFormik.touched.is_eligible_for_commission
                    }
                    id="is_eligible_for_commission"
                    disabled={props.isDisableFields}
                  />
                </div>
                <div className={`col-md-6 text-left`}>
                  <CheckBox
                    label="Limited Availability"
                    value={ProductFlagInfoFormik.values.is_limited_availability}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductFlagInfoFormik.setValues((v) => {
                        return { ...v, is_limited_availability: data };
                      })
                    }
                    error={ProductFlagInfoFormik.errors.is_limited_availability}
                    touched={
                      ProductFlagInfoFormik.touched.is_limited_availability
                    }
                    id="is_limited_availability"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-6 text-left`}>
                  <CheckBox
                    label="Sold Out"
                    value={ProductFlagInfoFormik.values.is_sold_out}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductFlagInfoFormik.setValues((v) => {
                        return { ...v, is_sold_out: data };
                      })
                    }
                    error={ProductFlagInfoFormik.errors.is_sold_out}
                    touched={ProductFlagInfoFormik.touched.is_sold_out}
                    id="is_sold_out"
                    disabled={props.isDisableFields}
                  />
                </div>
                <div className={`col-md-6 text-left`}>
                  <CheckBox
                    label="Exclude From Search"
                    value={ProductFlagInfoFormik.values.is_exclude_from_search}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductFlagInfoFormik.setValues((v) => {
                        return { ...v, is_exclude_from_search: data };
                      })
                    }
                    error={ProductFlagInfoFormik.errors.is_exclude_from_search}
                    touched={
                      ProductFlagInfoFormik.touched.is_exclude_from_search
                    }
                    id="is_exclude_from_search"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
              <div className={`row`}>
                <div className={`col-md-6 text-left`}>
                  <CheckBox
                    label="Temporarily Unavailable"
                    value={ProductFlagInfoFormik.values.temporary_unavailable}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductFlagInfoFormik.setValues((v) => {
                        return { ...v, temporary_unavailable: data };
                      })
                    }
                    error={ProductFlagInfoFormik.errors.temporary_unavailable}
                    touched={
                      ProductFlagInfoFormik.touched.temporary_unavailable
                    }
                    id="temporary_unavailable"
                    disabled={props.isDisableFields}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Variation"
        message="Are you sure want to delete this Variation?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteVariationId(null);
        }}
        handleSubmitClick={handleDeleteVariation}
      /> */}

      <ManageVariationModal
        showModal={showAddModal}
        pageTitle={manageVariationPageTitle}
        submitStatus={submitStatus}
        handleCancelClick={(e) => {
          setShowAddModal(false);
          if (productState.setEditVariantProductIndex === "")
            deleteDataProductVariant(productState.activeProductVariantIndex);
        }}
        handleSubmitClick={handleManageVariation}
        onChange={(type, data) => {
          if (type === "Variation") setVariation(data);
        }}
        isDisableFields={props.isDisableFields}
      />
    </React.Fragment>
  );
}

export default ProductVariations;

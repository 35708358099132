import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import styles from "../../Products/index.module.scss";
import AddIcon from "../../../Assets/images/add.svg";
import { useFormikContext } from "formik";
import EditIcon from "../../../Assets/images/edit.svg";
import { checkPriceDefference } from "Pages/Order/Order/utility/util";

const TableMap = ({
  title,
  list,
  OnAddClick,
  onIncrease,
  onDecrease,
  onDelete,
  OnAddClickCustom,
  module,
  onEdit,
  orderProducts,
}) => {
  let tr = (
    <tr>
      <td className={``}>
        <div className={`${styles.emptyImage}`}>
          <span>Image</span>
        </div>
      </td>
      <td className={``}>No {title}</td>
      <td className={` text-center`}>-</td>
      <td className={` text-center`}>-</td>
      <td className={` text-center`}>-</td>
      <td className={` text-center`}>-</td>
      <td className={` text-center `}></td>
    </tr>
  );
  const formik = useFormikContext();
  if (list.length > 0) {
    tr = list.map((d, i) => {
      const orderProduct = orderProducts?.find((p)=>p?.variation_id === d?.selectedVariant?.id);
      const isPriceNotSame = checkPriceDefference(d.selectedVariant.price, orderProduct?.unit_price);

      return (
      <tr className={`${styles.tableStyle}`} key={i}>
        <td className={``}>
          <div className={`${styles.tableimg} ${d?.status === 0 || d?.status === 2 ? styles.inactiveProduct : ""}`}>
            <img src={d.selectedVariant?.image} alt="" title="image" />
          </div>
        </td>
        <td className={`${d?.status === 0 || d?.status === 2 ? styles.inactiveProduct : ""}`}>
          {d.title} <br />
          <small>{d.selectedVariant?.title}</small>
        </td>
        <td className={styles.quantityCol}>
          <div className={`d-flex align-items-center`}>
            <div className={`${styles.quantityLabel} ${d?.status === 0 || d?.status === 2 ? styles.inactiveProduct : ""}`}>{d.qty}</div>
            <ButtonGroup className={`incressdecress ml-3`}>
              <Button disabled={d.qty <= 1 || (formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq !== 1)} onClick={() => onDecrease(i)}>
                -
              </Button>
              <Button disabled={d.qty >= 1000 || (formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq !== 1)} onClick={() => onIncrease(i)}>
                +
              </Button>
            </ButtonGroup>
          </div>
        </td>
        <td className={` text-right`} style={{ minWidth: "90px", textWrap: "nowrap" }}>
          {module === "edit-order" && orderProduct && !isPriceNotSame && (
            <small style={{color: "#F37C7C", fontSize:14}}>
              {`(${orderProduct?.unit_price?.toFixed(2)}) `}
            </small>
          )}
          {d.selectedVariant.price.toFixed(2)} 
        </td>
        <td className={` text-right`} style={{ minWidth: "90px" }}>
          {d.total_price.toFixed(2)}
        </td>
        <td className={` text-center `}>
          <div className={`d-flex`}>
            {(formik.values.is_burq_order !== 1 || (formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq === 1)) &&
              <DeleteOutlineIcon
                onClick={() => {
                  if (d?.sqlid) {
                    onDelete(i, d.sqlid);
                  } else {
                    onDelete(i);
                  }
                }}
                style={{ color: "#F37C7C", cursor: "pointer" }}
              />}
            {module === 'edit-order' &&
              <img
                className={`cursor-pointer`}
                style={{width:22, height:22,marginLeft:5,marginTop:2}}
                src={EditIcon}
                alt="Edit Order"
                onClick={() => {
                  onEdit(i, d, title);
                }}
              />
            }
            
          </div>
        </td>
      </tr>
    )});
  }

  return (
    <div className={`${styles.productsWrapper}`}>
      <div className={styles.title}>{title}</div>
      {(formik.values.is_burq_order !== 1 || (formik.values.is_burq_order === 1 && formik.values.is_cancel_fail_by_burq === 1)) &&
        <button
          type="button"
          className={`btn pink-btn ml-2 ${styles.orderButton} w-auto`}
          onClick={OnAddClick} 
          disabled={!formik.values.billing_info.zip_code_store_id}  
          style={{width:36, minWidth:20, padding:"7px 5px"}}>
        <img className={styles.searchIcon} src={AddIcon} alt="SearchIcon" style={{width:20, height:19}}/>
      </button>
      }
      
      {title && title === "Products" &&  (
        <button
        type="button"
        disabled={
          (formik.values.is_burq_order === 1 &&
            formik.values.is_cancel_fail_by_burq !== 1) ||
            !formik.values.billing_info.zip_code_store_id
        }
        className={`btn pink-btn ml-2 ${styles.orderButton}`}
        onClick={OnAddClickCustom}
      >
        Add Custom Product
      </button>
      )}
      <div className="table-responsive mt-2 mb-4">
        <table className="table">
          <thead>
            <tr>
              <th className="text-left">Item</th>
              <th className="text-left">Name</th>
              <th className="text-left">Qty</th>
              <th className="text-right">Price</th>
              <th className="text-right">Total Price</th>
              <th />
            </tr>
          </thead>
          <tbody>{tr}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TableMap;

import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import Seo from "../../../Components/Cms/Seo";
import OGInfo from "../../../Components/Cms/OgInfo";
import Twitterinfo from "../../../Components/Cms/TwitterInfo";
// import Editer from "../../../Components/Cms/Schema";
import Header from "../../../Components/Header/Header";
// import Banner from "../../../Components/Cms/Banner";
import Goto from "../../../Components/Cms/Goto";
import PrimaryInformation from "../../../Components/Products/PrimaryInformation";
import Variations from "../../../Components/Products/ProductVariations";
import DeliveryInfo from "../../../Components/Products/DeliveryInfo";
import SimilarProduct from "../../../Components/Products/SimilarProduct";
// import Notes from "../../../Components/Products/ProductNotes";
import ProductCategory from "../../../Components/Products/ProductCategory";
import Navigation from "../../../Components/Products/Navigation";
// import Products from "../../../Components/Products/Products";
import classes from "./index.module.scss";

import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "../../../Context/AppContext";
import { DEFAULT_STORE_ID, MODULES } from "../../../Helpers/Constants";

import { postReqProduct } from "../../../Hooks/product/productDataApiCall";
import { useProduct } from "../../../Context/ProductCrud/useProduct";

import productApi from "../../../Services/Products/Products";
import UsersApi from "./../../../Services/Users/Users";
import CategoryApi from "./../../../Services/Category/Category";
import {
  updateProduct,
  createProduct,
  getProductDetail,
  getSimilarProductDetail,
} from "api/product";
import {getUserList} from "api/user";
import {getCategoryHierarchyList} from "api/category";

// import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";

const AddProduct = (props) => {
  // const history = useHistory();
  const [productIdUpdate, setProductIdUpdate] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [loadData, setLoadData] = useState(false);

  const productApiService = new productApi();
  const usersService = new UsersApi();
  const categoryService = new CategoryApi();

  //use for update case for get update id

  const [access, setAccess] = useState({});

  useEffect(() => {
    clearProductState();
  }, []);

  useEffect(() => {
    (async () => {
      const { updateId, view } = props.match.params;
      await setProductIdUpdate(updateId);
      await handleGetAllCategoryDetailById();
      await setPageForView(0);
      if (view !== undefined && view != null) {
        setPageForView(1);
      }
      await getAllUserList();
      setLoadData(true);
    })();
  }, []);

  // useEffect(() => {
  //   getAllUserList();
  // }, []);

  //use in update case for get product detail
  useEffect(() => {
    if (loadData && productIdUpdate) {
      handleGetProductDetailById(productIdUpdate);
    }
  }, [productIdUpdate, loadData]);

  const gotoList = [
    { id: "primary-information", title: "Primary Information" },
    // { id: "banner-section", title: "Banner Style" },
    { id: "variations", title: "Variations" },
    { id: "seo-section", title: "SEO" },
    { id: "is-local", title: "Is Local" },
    { id: "similar-products", title: "Similar Products" },
    // { id: "notes", title: "Notes" },
    { id: "navigation", title: "Navigation" },
  ];

  const {
    productState,
    setActiveIndexVariant,
    setProductPrimaryData,
    setIsLocal,
    setSeoData,
    checkSeoData,
    clearProductState,
    setProductAllData,
    setProductVariantData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    // checkSchemaData,
    // setSimilarProductData,
    setActiveIndexSimilarProduct,
    setProductImageData,
    setUserData,
    setPageForView,
    setCategoryHierarchyData,
    deleteCategoryHierarchyData,
    setProductNavigationData,
    checkProductNavigationData,
    setProductFlagData,
    setProductNoteData,
    // addNewBanner,
    // removeBanner,
    // setBannerData,
    // checkBannerData,
    setProductBannerData,
    addSimilarProductData,
    deleteSimilarProductData,
    // setActiveIndexNote,
    setSimilarProductArray,
  } = useProduct();

  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);

  // Final product Call
  const handleSubmitClick = async (event) => {
    event.preventDefault();

    let productApiCall = true;

    const primaryInfoForm = document.getElementById(
      "primary_info_submit_button"
    );
    if (primaryInfoForm) {
      primaryInfoForm.click();
    }

    const navigationInfoForm = document.getElementById(
      "navigation_info_submit_button"
    );
    if (navigationInfoForm) {
      navigationInfoForm.click();
    }

    // const seoSubmit = document.querySelector(".seo-submit-button");
    // if (seoSubmit) {
    //   seoSubmit.click();
    // }

    // const ogInfoSubmit = document.querySelector(".og-info-submit-button");
    // if (ogInfoSubmit) {
    //   ogInfoSubmit.click();
    // }

    // const twitterSubmit = document.querySelector(".twitter-submit-button");
    // if (twitterSubmit) {
    //   twitterSubmit.click();
    // }

    // const schemaSubmit = document.querySelector(".schema-submit-button");
    // if (schemaSubmit) {
    //   schemaSubmit.click();
    // }

    const bannerSubmit = document.querySelector(".banner-submit-button");
    if (bannerSubmit) {
      bannerSubmit.click();
    }

    let validationMessage = "";
    if (productState.productPrimaryError) {
      productApiCall = false;
      validationMessage = "Please add proper data of Item";
    } else if (
      !productState.product_primary_info.title ||
      !productState.product_primary_info.description
    ) {
      productApiCall = false;
      validationMessage =
        "Please add proper data of product primary information";
    } else if (productState.productNavigationError) {
      productApiCall = false;
      validationMessage = "Please add proper data of slug";
    } else if (
      productState.product_variations &&
      productState.product_variations.length === 0
    ) {
      productApiCall = false;
      validationMessage = "Please add at least one variation of product ";
    } else if (
      !productState.product_variations[0]["title"] &&
      !productState.product_variations[0]["price"]
    ) {
      productApiCall = false;
      validationMessage = "Please add proper data of variation";
    }
    // else if (productState.seoError) {
    //   validationMessage = "Please add proper data of seo data";
    //   productApiCall = false;
    // } else if (productState.schemaError) {
    //   productApiCall = false;
    //   validationMessage = "Please add proper data of seo twitter schema";
    // } else if (productState.twitterInfoError) {
    //   productApiCall = false;
    //   validationMessage = "Please add proper data of seo twitter Data";
    // } else if (productState.ogInfoError) {
    //   productApiCall = false;
    //   validationMessage = "Please add proper data of seo Og Info";
    // }
    else if (productState.product_images.length === 0) {
      productApiCall = false;
      validationMessage = "Please product image";
    }
    // else if (productState.bannerError) {
    //   productApiCall = false;
    //   showToastMessage(
    //     undefined,
    //     `Please enter required banner details`,
    //     "error",
    //     false
    //   );

    // }
    // console.log(productState);
    // productApiCall = true;
    if (productApiCall) {
      setIsLoading(true);
      const post_req = postReqProduct(productState);
      // console.log(post_req);
      // return false;
      if (productIdUpdate !== "" && productIdUpdate !== undefined) {
        // let response = await productApiService.updateProduct(
        //   productIdUpdate,
        //   post_req
        // );
        let response = await updateProduct(productIdUpdate, post_req);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearProductState();
          setIsLoading(false);
          props.history.push("/product-catalog/product-management");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } else {
        // let response = await productApiService.createProduct(post_req);
        let response = await createProduct(post_req);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearProductState();
          setIsLoading(false);
          props.history.push("/product-catalog/product-management");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
    } else {
      if (validationMessage === "") {
        validationMessage = "Please add required data";
      }
      showToastMessage(undefined, validationMessage, "error", false);
    }
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Access Contorol functionm call
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.PRODUCT_ADDONS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  //get product detail data for update case by product id
  const handleGetProductDetailById = async (updateId) => {
    setIsLoading(true);
    // const response = await productApiService.getProductDetail(updateId);
    const response = await getProductDetail(updateId);
    if (response && response.status === 200) {
      if (response.data.data) {
        let tempResponseData = { ...response.data.data };

        setProductAllData(tempResponseData);

        tempResponseData.product_variations?.map((d, i) => {
          setProductVariantData(tempResponseData.product_variations[i]);
          setActiveIndexVariant(i + 1);
        });

        tempResponseData.product_images?.map((d, i) => {
          tempResponseData.product_images[i]["src"] =
            tempResponseData.product_images[i]["image"];
          setProductImageData(tempResponseData.product_images[i]);
        });

        tempResponseData.product_categories?.map((d, i) => {
          let filterData = categoryList?.filter((data) => {
            if (data.category_hierarchy_id === d.category_hierarchy_id) {
              return data;
            }
          });
          let tempArr = {
            title: filterData[0].title,
            slug: filterData[0].slug,
            category_hierarchy_id: d.category_hierarchy_id,
            categoryId: d.id,
          };
          // categoryDataArr.push(tempArr);
          setCategoryHierarchyData(tempArr);
        });

        tempResponseData.banners?.map((d, i) => {
          tempResponseData.banners[i]["module"] = "products";
          setProductBannerData(tempResponseData.banners[i]);
        });

        if (tempResponseData.product_notes.length > 0) {
          tempResponseData.product_notes?.map((d, i) => {
            tempResponseData.product_notes[i]["user_id"] =
              tempResponseData.product_notes[i].user.id;
            tempResponseData.product_notes[i]["user_name"] =
              tempResponseData.product_notes[i].user.full_name;
            tempResponseData.product_notes[i]["date"] = moment
              .unix(tempResponseData.product_notes[i]["date"])
              .utc()
              .format("YYYY-MM-DD");
            // setActiveIndexNote(i);
            setProductNoteData(tempResponseData.product_notes[i]);
          });
        }

        setSeoData(tempResponseData.seo);
        setOgInfoData(tempResponseData.seo);
        setTwitterInfoData(tempResponseData.seo);
        setSchemaData(tempResponseData.seo);
        setProductPrimaryData(tempResponseData);

        setProductNavigationData({
          slug: tempResponseData.slug,
          blurb: tempResponseData.blurb,
        });

        setProductFlagData({
          is_eligible_for_commission:
            tempResponseData.is_eligible_for_commission,
          is_limited_availability: tempResponseData.is_limited_availability,
          is_sold_out: tempResponseData.is_sold_out,
          is_exclude_from_search: tempResponseData.is_exclude_from_search,
          temporary_unavailable: tempResponseData.temporary_unavailable,
        });

        setIsLocal(tempResponseData.is_local_only);

        handleGetSimilarProductDetailById(updateId);
        // handleGetAllCategoryDetailById();
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleGetSimilarProductDetailById = async (updateId) => {
    setIsLoading(true);
    // const response = await productApiService.getSimilarProductDetail(updateId);
    const response = await getSimilarProductDetail(updateId);
    if (response && response.status === 200) {
      if (response.data.data) {
        let similayProductArrayList = [];
        let tempResponseData = { ...response.data.data };
        tempResponseData.rows?.map((d, i) => {
          similayProductArrayList.push(tempResponseData.rows[i].product);
          setActiveIndexSimilarProduct(i + 1);
        });
        setSimilarProductArray(similayProductArrayList);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleGetAllCategoryDetailById = async () => {
    setIsLoading(true);

    // let postData = {
    //   globalSearch: "",
    //   filters: [],
    //   sortBy: [
    //     {
    //       id: "title",
    //       asc: "asc",
    //     },
    //   ],
    // };

    // const response = await categoryService.getCategoryNormalList(postData);
    // const response = await categoryService.getCategoryHierarchyList();
    const response = await getCategoryHierarchyList();

    if (response && response.status === 200) {
      let categoryDataArr = [];
      // response.data.data.rows?.map((d, i) => {
      response.data.data?.map((d, i) => {
        // if (d.category_hierarchies && d.category_hierarchies.length > 0) {
        let tempArr = {
          title: d.title,
          slug: d.slug,
          category_hierarchy_id: d.category_hierarchy_id,
          categoryId: d.id,
        };
        categoryDataArr.push(tempArr);
        // }
      });
      setCategoryList(categoryDataArr);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const getAllUserList = async () => {
    setIsLoading(true);
    const reqBody = {
      currentPage: 0,
      itemsPerPage: 100,
      globalSearch: "",
      filters: [],
      sortBy: [],
    };
    // const response = await usersService.getUserList(reqBody);
    const response = await getUserList(reqBody);

    if (response && response.status === 200) {
      let tempResponseDataUser = { ...response.data.data };
      setUserData(tempResponseDataUser.rows);
    } else showToastMessage(undefined, response.data.message, "error", false);
    setIsLoading(false);
  };

  //Get current selected store Id
  let selectedStore = localStorage.getItem("selectedStore");

  return (
    <React.Fragment>
      {productIdUpdate !== "" && productIdUpdate !== undefined ? (
        <Header title="Edit Product" />
      ) : (
        <Header title="Add Product" />
      )}
      <div className="row">
        <div id="MainContent" className="col-8">
          <div className={`${classes.cardBody}`}>
            <CardContent>
              <PrimaryInformation isDisableFields={selectedStore != DEFAULT_STORE_ID}/>
              {/* comment by darshan for not give this in release on 1-7-2021 */}
              {/* <Banner
                bannerModule="products"
                bannersData={productState.banners}
                addNewBanner={addNewBanner}
                removeBanner={removeBanner}
                setBannerData={setBannerData}
                checkBannerData={checkBannerData}
                uploadImageType="PRODUCT_BANNER"
                setPageForView={productState.setPageForView}
              /> */}
              <Variations isDisableFields={selectedStore != DEFAULT_STORE_ID}/>
              <Seo
                data={productState.seo}
                setSeoData={setSeoData}
                checkSeoData={checkSeoData}
                isDisableFields={selectedStore != DEFAULT_STORE_ID}
              />
              <OGInfo
                data={productState.seo}
                setOgInfoData={setOgInfoData}
                checkOgInfoData={checkOgInfoData}
                isDisableFields={selectedStore != DEFAULT_STORE_ID}
              />
              <Twitterinfo
                data={productState.seo}
                setTwitterInfoData={setTwitterInfoData}
                checkTwitterInfoData={checkTwitterInfoData}
                isDisableFields={selectedStore != DEFAULT_STORE_ID}
              />
              {/* <Editer
                data={productState.seo}
                setSchemaData={setSchemaData}
                checkSchemaData={checkSchemaData}
              /> */}
              <DeliveryInfo
                onChange={(data) => {
                  setIsLocal(data);
                }}
                isDisableFields={selectedStore != DEFAULT_STORE_ID}
              />
              <SimilarProduct
                productList={productState.similar_products}
                addProductList={(data) => addSimilarProductData(data)}
                removeProductList={(data) => deleteSimilarProductData(data)}
                setPageForView={productState.setPageForView}
              />
              {/* comment by darshan for not give this in release on 1-7-2021 */}
              {/* <Notes /> */}
            </CardContent>
          </div>
        </div>
        <div className="col-4">
          <Goto
            handleSubmitClick={handleSubmitClick}
            notShowSaveButton={productState.setPageForView}
            gotoList={gotoList}
          />
          <ProductCategory
            setPageForView={productState.setPageForView}
            categoryList={categoryList}
            selectedCategoryList={productState.product_categories}
            setCategoryHierarchyData={setCategoryHierarchyData}
            deleteCategoryHierarchyData={deleteCategoryHierarchyData}
            isDisableFields={selectedStore != DEFAULT_STORE_ID}
          />
          <Navigation
            slugData={productState.setProductAllData.slug}
            blurbData={productState.setProductAllData.blurb}
            setNavigationData={setProductNavigationData}
            checkNavigationData={checkProductNavigationData}
            isDisableFields={selectedStore != DEFAULT_STORE_ID}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AddProduct);

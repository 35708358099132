import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../Context/AppContext";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddBlackIcon from "./../../../Assets/images/add-black.svg";

// SCSS
import styles from "./index.module.scss";
// Component
import Text from "./../../FormControl/Text/Text";
import TextArea from "../../FormControl/TextArea/TextArea";
import CheckBox from "../../FormControl/CheckBox/CheckBox";

import { ProductVariationInfoForm } from "../../../Hooks/product/productVaraitionFormFromik";
import { useProduct } from "../../../Context/ProductCrud/useProduct";

import UtilsApi from "../../../Services/Utils/Utils";
import { uploadImage } from "../../../api/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddChangeRequest = ({
  showModal,
  pageTitle,
  submitStatus,
  Title,
  SKU,
  onChange,
  Comission,
  priceSale,
  Price,
  InventoryRemaining,
  handleCancelClick,
  handleSubmitClick,
  setEditVariantProductIndex,
  isDisableFields
}) => {
  const utilsService = new UtilsApi();
  const { showToastMessage, setIsLoading } = useContext(AppContext);
  const [defaultFlagVal, setDefaultFlagVal] = useState("");

  const {
    productState,
    setProductVariantData,
    checkProductVariantData,
  } = useProduct();

  let productVariantValue = {
    id:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.id ?? null,
    title:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.title ?? "",
    sku:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.sku ?? "",
    commission:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.commission ?? 0,
    price:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.price ?? "",
    sale_price:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.sale_price ?? "",
    inventory:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.inventory ?? 100,
    image:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.image ?? "",
    status:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.status ?? 0,
    is_default_selected:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.is_default_selected ?? false,
    is_sold_out:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.is_sold_out ?? false,
    placeholder_background:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.placeholder_background ?? "",
    placeholder_text:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.placeholder_text ?? "",
    checklist:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.checklist ?? "",
    important_note:
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.important_note ?? "",
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    setProductVariantData(values);
    // setProductVariantDataTest(values);
  };
  // console.log("productVariantValue==>", productVariantValue);
  const { formik: ProductVaraitionFormFromik } = ProductVariationInfoForm(
    submit,
    productVariantValue,
    cbSubmit,
    checkProductVariantData
  );

  useEffect(() => {
    let disableFlag = false;
    if (productState.product_variations.length > 0) {
      // eslint-disable-next-line array-callback-return
      productState.product_variations?.map((d, i) => {
        if (d.is_default_selected) disableFlag = true;
      });
    }

    if (
      productState.product_variations[productState.setEditVariantProductIndex]
        ?.is_default_selected
    )
      disableFlag = false;

    setDefaultFlagVal(disableFlag);
  }, [productState]);

  const uploadImageRef = React.useRef();
  // const placeholderImageRef = React.useRef();

  const handleUploadImage = async (imageData, imageType = "PRODUCT") => {
    let imageFormdata = new FormData();
    imageFormdata.append("image", imageData);
    imageFormdata.append("type", imageType);
    setIsLoading(true);
    // const response = await utilsService.uploadImage(imageFormdata);
    const response = await uploadImage(imageFormdata);
    if (response && response.status === 200) {
      setIsLoading(false);
      return response.data.data;
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          ProductVaraitionFormFromik.handleSubmit(e);
        }}
        className={`row`}
      >
        <Dialog
          open={showModal}
          TransitionComponent={Transition}
          // onClose={handleCancelClick}
          onClose={() => {
            if (productState.setEditVariantProductIndex === "") {
              let values = {
                title: "",
                sku: "",
                commission: "",
                price: "",
                sale_price: "",
                inventory: "",
                image: "",
                status: "",
                is_default_selected: "",
                is_sold_out: "",
                placeholder_background: "",
                placeholder_text: "",
                checklist: "",
                important_note: "",
              };
              ProductVaraitionFormFromik.setValues((v) => {
                return {
                  ...values,
                };
              });
              setProductVariantData(values);
            }
            if (handleCancelClick) {
              handleCancelClick();
            }
          }}
          classes={{
            paper: styles.modalDialog,
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent className={`${styles.modalContent} px-0 py-0`}>
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {pageTitle}
              </h5>
              <div className="row">
                <input
                  type="hidden"
                  value={ProductVaraitionFormFromik.values.id}
                  id="id"
                />
                <div className="col-md-4 col-xs-12">
                  <Text
                    type="text"
                    label="Title"
                    value={ProductVaraitionFormFromik.values.title}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, title: data };
                      })
                    }
                    error={ProductVaraitionFormFromik.errors.title}
                    touched={ProductVaraitionFormFromik.touched.title}
                    id="title"
                    disabled={isDisableFields}
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <Text
                    type="text"
                    label="SKU"
                    value={ProductVaraitionFormFromik.values.sku}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, sku: data };
                      })
                    }
                    error={ProductVaraitionFormFromik.errors.sku}
                    touched={ProductVaraitionFormFromik.touched.sku}
                    id="title"
                    disabled={isDisableFields}
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <Text
                    type="number"
                    label="Comission"
                    value={ProductVaraitionFormFromik.values.commission.toString()}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, commission: +data };
                      })
                    }
                    error={ProductVaraitionFormFromik.errors.commission}
                    touched={ProductVaraitionFormFromik.touched.commission}
                    id="commission"
                    disabled={isDisableFields}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <Text
                    type="number"
                    label="Price"
                    value={ProductVaraitionFormFromik.values.price}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, price: +data };
                      })
                    }
                    error={ProductVaraitionFormFromik.errors.price}
                    touched={ProductVaraitionFormFromik.touched.price}
                    id="price"
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <Text
                    type="number"
                    label="Sales Price"
                    value={ProductVaraitionFormFromik.values.sale_price}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, sale_price: +data };
                      })
                    }
                    error={ProductVaraitionFormFromik.errors.sale_price}
                    touched={ProductVaraitionFormFromik.touched.sale_price}
                    id="sale_price"
                  />
                </div>
                {/* comment by darshan for not give this in release on 1-7-2021 */}
                {/* <div className="col-md-4 col-xs-12">
                  <Text
                    type="text"
                    label="Inventory Remaining"
                    value={ProductVaraitionFormFromik.values.inventory}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, inventory: data };
                      })
                    }
                    error={ProductVaraitionFormFromik.errors.inventory}
                    touched={ProductVaraitionFormFromik.touched.inventory}
                    id="inventory"
                  />
                </div> */}
              </div>
              <div className={`row`}>
                {!isDisableFields ? (
                  <div className="col-md-3 col-xs-12">
                    <div
                      onClick={() => {
                        uploadImageRef.current.click();
                      }}
                      className={styles.uploadBox}
                    >
                      <input
                        type="file"
                        className="d-none"
                        ref={uploadImageRef}
                        accept="image/*"
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          const uploadResponse = await handleUploadImage(file);
                          ProductVaraitionFormFromik.setValues((v) => {
                            return {
                              ...v,
                              image: uploadResponse[0]?.url,
                            };
                          });
                        }}
                      />
                      {ProductVaraitionFormFromik.values.image ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={ProductVaraitionFormFromik.values.image}
                          alt="upload_Image_URL"
                        />
                      ) : (
                        <img src={AddBlackIcon} alt="upload_Image_URL" />
                      )}
                    </div>
                  </div>
                ):(
                  <div className="col-md-3 col-xs-12">
                    <div className={styles.uploadBox}>
                      {ProductVaraitionFormFromik.values.image ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={ProductVaraitionFormFromik.values.image}
                          alt="upload_Image_URL"
                        />
                      ) : (
                        <img src={AddBlackIcon} alt="upload_Image_URL" />
                      )}
                    </div>
                  </div>
                )}
                <div className={`col-md-3 col-xs-12`}>
                  {/* comment by darshan for not give this in release on 1-7-2021 */}
                  {/* <label className={`bmd-label-floating ${styles.imageLabel}`}>
                    Upload Background
                  </label> */}
                </div>
                <div className="link cursor-pointer col-md-6 col-xs-12">
                  <label className={styles.statusLabel}>Status</label>

                  {ProductVaraitionFormFromik.values.status === 1 ? (
                    <div className={styles.status}>
                      <div
                        className={styles.Active}
                        style={{ "font-weight": "bold" }}
                      >
                        Active
                      </div>
                      <div
                        className={styles.Inactive}
                        onClick={(data) =>
                          ProductVaraitionFormFromik.setValues((v) => {
                            return { ...v, status: 0 };
                          })
                        }
                      >
                        Inactive
                      </div>
                    </div>
                  ) : (
                    <div className={styles.status}>
                      <div
                        className={styles.Active}
                        onClick={(data) =>
                          ProductVaraitionFormFromik.setValues((v) => {
                            return { ...v, status: 1 };
                          })
                        }
                      >
                        Active
                      </div>
                      <div
                        className={styles.Inactive}
                        style={{ "font-weight": "bold" }}
                      >
                        Inactive
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`row`}>
                <div className="col-md-6 col-xs-12 text-left">
                  <CheckBox
                    label="Default Selected"
                    value={
                      ProductVaraitionFormFromik.values.is_default_selected
                    }
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, is_default_selected: data };
                      })
                    }
                    disabled={defaultFlagVal || isDisableFields}
                  />
                </div>
                <div className="col-md-6 col-xs-12 text-left">
                  <CheckBox
                    className={`text-left`}
                    label="Sold Out"
                    value={ProductVaraitionFormFromik.values.is_sold_out}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, is_sold_out: data };
                      })
                    }
                    disabled={isDisableFields}
                  />
                </div>
              </div>
              {/* comment by darshan for not give this in release on 1-7-2021 */}
              {/* <div className={`row`}>
                <div className="col-md-3 col-xs-12">
                  <div
                    onClick={() => {
                      placeholderImageRef.current.click();
                    }}
                    className={styles.uploadBox}
                  >
                    <input
                      type="file"
                      className="d-none"
                      ref={placeholderImageRef}
                      accept="image/*"
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        const uploadResponse = await handleUploadImage(file);
                        ProductVaraitionFormFromik.setValues((v) => {
                          return {
                            ...v,
                            placeholder_background: uploadResponse[0].url,
                          };
                        });
                      }}
                    />
                    {ProductVaraitionFormFromik.values
                      .placeholder_background ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={
                          ProductVaraitionFormFromik.values
                            .placeholder_background
                        }
                        alt="Placeholder Background URL"
                      />
                    ) : (
                      <img
                        src={AddBlackIcon}
                        alt="Placeholder Background URL"
                      />
                    )}
                  </div>
                </div>
                <div className={`col-md-3 col-xs-12`}>
                  <label className={`bmd-label-floating  ${styles.imageLabel}`}>
                    Placeholder Background
                  </label>
                </div>
                <div className="col-md-6 col-xs-12">
                  <Text
                    type="text"
                    label="Placeholder Text"
                    value={ProductVaraitionFormFromik.values.placeholder_text}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, placeholder_text: data };
                      })
                    }
                    submitStatus={submitStatus}
                  />
                </div>
              </div> */}
              <div className={`row`}>
                <div className="col-md-12 col-xs-12">
                  <Text
                    type="text"
                    label="Add Important Note"
                    value={ProductVaraitionFormFromik.values.important_note}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, important_note: data };
                      })
                    }
                    disabled={isDisableFields}
                  />
                </div>
              </div>
              <div className={`row`}>
                <div className="col-md-12 col-xs-12">
                  <TextArea
                    type="text"
                    label="Checklist"
                    value={ProductVaraitionFormFromik.values.checklist}
                    required={true}
                    onChange={(data) =>
                      ProductVaraitionFormFromik.setValues((v) => {
                        return { ...v, checklist: data };
                      })
                    }
                    disabled={isDisableFields}
                  />
                </div>
              </div>
              <button
                type="submit"
                data-dismiss="modal"
                onClick={handleSubmitClick}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Save
              </button>
              <button
                data-dismiss="modal"
                onClick={() => {
                  if (productState.setEditVariantProductIndex === "") {
                    let values = {
                      title: "",
                      sku: "",
                      commission: "",
                      price: "",
                      sale_price: "",
                      inventory: "",
                      image: "",
                      status: "",
                      is_default_selected: "",
                      is_sold_out: "",
                      placeholder_background: "",
                      placeholder_text: "",
                      checklist: "",
                      important_note: "",
                    };
                    ProductVaraitionFormFromik.setValues((v) => {
                      return {
                        ...values,
                      };
                    });
                    setProductVariantData(values);
                  } else {
                    if (
                      productState.setProductAllData.product_variations[
                        productState.setEditVariantProductIndex
                      ] !== undefined &&
                      productState.setProductAllData.product_variations[
                        productState.setEditVariantProductIndex
                      ] !== ""
                    )
                      setProductVariantData(
                        productState.setProductAllData.product_variations[
                          productState.setEditVariantProductIndex
                        ]
                      );
                  }
                  if (handleCancelClick) {
                    handleCancelClick();
                  }
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Cancel
              </button>
            </div>
          </DialogContent>
        </Dialog>
        <button
          className="d-none"
          id="product_variant_submit_button"
          type="submit"
        ></button>
      </form>
    </React.Fragment>
  );
};

export default AddChangeRequest;
